div.container-custom {
  display: grid;
  justify-content: right;
}

a.button-csv {
  background-color: #5b3a89;
  border-radius: 5px;
  color: aliceblue;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  padding: 0.5rem;
  margin-bottom: 1rem;
  width: 12rem;
}

a.button-csv:hover {
  background-color: #4f3376;
}
